.modalFooter{
    margin-left: auto;
    margin-right: auto;
}

.btn-container{
    padding: 0!important;
    margin-bottom: .5rem;
}

.contact-btn{
    color: white;
    text-decoration: none;
}
.contact-btn:hover{
    color: white;
}


@media (min-width: 576px){

.modal-dialog {
    max-width: 800px!important;
    line-height: 1.6rem;
}

.modal-body{
    display: flex;
}
}

.modal-content{
    padding: 1rem;
    border: none!important;
    border-radius: .5rem;
}