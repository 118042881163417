
.contactanos{
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right,#1DB9A0, #4d94c4);
    flex-wrap: wrap;
    color: white;
    text-align: center;
  }

  .contactanos a{
      text-decoration: none;
      color: #FFF;
  }

  .contact-item{
    text-align: center;
      margin:50px auto;
  }

  @media (max-width:860px){
    .contact-item{
      margin: 20px 50px;
    }
  }

  .contact-item h4{
    margin-bottom: 1rem;
    text-align: left;
  }

ul.contact-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
  
.contact-list-item{
      color: #ffffff;
      list-style: none;
      text-decoration: none;
      text-align: start;
      margin-bottom: 1rem;
    }

  .contact-list-item-icon,.contact-list-item-text{
    display: inline;
    text-decoration: none;
    color: #ffffff;
    line-height: 200%;
  }

  .contact-list-item-icon i{
    align-self: center;
    font-size: 1.5rem;
    text-align: center;
    width: 1.5rem;
    margin-right: 0.5rem;
  }

  .contact-icon{
    font-size: 2rem;
    padding: 1rem;
  }

  .contact-icon:hover{
    opacity: 0.8;
  }