.hero{
    color: white;
    margin: 0 auto;
    margin-bottom: 2rem;
    background-image: linear-gradient(to right,#1270E3, #59C2FF);
    position: relative;
    /* background: url(src/bg3.jpg) center/cover no-repeat; */
    }
    .overlayimg{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-position: center;
        background-size:cover ;
        opacity: 0.3;
        z-index: 1;
        background-image: url(./img/bg3.jpg);
    }

    .hero-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .hero-content img, .hero-content h1{
        z-index: 1;
      }
      
      .hero h1{
        align-self: center;
        text-shadow:  2px 2px 5px rgb(29, 29, 29);
      }
      
      .hero img{
      
        width: 150px;
        height: auto;
      }

      @media (max-width:750px) {
        .hero-content{
          padding: 1rem 1rem 0 1rem;
          text-align: center;
        }
      }