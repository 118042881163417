.Button{
    margin-left: 10px;
    margin-top: 5px;
}

#Card{
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 1.5rem;
    max-height: 600px;
    border: none;
    box-shadow: 1px 1px 8px -3px #8a8a8ab7;
    border-radius: .5rem;
}


.card-title{
    font-size: larger;
    font-weight: 700;
}

.card-subtitle{
    color: #525252;
}
