

.navbar{
    background: linear-gradient(to right,#1DB9A0, #4d94c4);
    
    width: 100vw;

    vertical-align: middle;
    padding: 2rem 0!important;
}

.nav-link{
    color: white!important;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.navbar-toggler-icon{
    background: initial;
    
}

.navbar-toggler{
    color: white!important;
    font-size: 2rem!important;
}
/* 440 */
@media (max-width: 440px){
.navbar{
    max-height: initial;
    padding: 1rem 0!important;
}
}
.container-fluid{
    justify-content: space-around!important;
}

.nav-link{
    color: white!important;
    margin: 0 1rem;
}

.navbar-nav{
    /* justify-content: space-around; */
}

@media (min-width: 768px){
    .navbar{    
        max-height: 100px;  
    }
    .navbar-collapse{
        flex-grow: 0!important;
    }


}